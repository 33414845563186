import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import { usdt_abi } from './usdt.abi';

// 1. Get projectId
const projectId = 'ec2399d45d6d12326a12a50a09384a5b'

// 2. Set chains
/* TEST:
const mainnet = {
	chainId: 0x61,
	name: 'Binance Smart Chain Testnet',
	currency: 'tBNB',
	explorerUrl: 'https://testnet.bscscan.com',
	rpcUrl: 'https://data-seed-prebsc-1-s3.binance.org:8545'
}*/

const mainnet = {
	chainId: 0x38,
	name: 'Binance Smart Chain',
	currency: 'BNB',
	explorerUrl: 'https://bscscan.com/',
	rpcUrl: 'https://bsc-dataseed1.binance.org/',
}

// 3. Create a metadata object
const metadata = {
	name: 'sunmoney-walletconect-demo',
	description: 'Sunmoney Walletconnect demo',
	//url: 'https://walletconnect-demo.sunmoney.net', // origin must match your domain & subdomain
	//icons: ['https://walletconnect-demo.sunmoney.net/icons']
	url: 'https://localhost:3000', // origin must match your domain & subdomain
	icons: ['https://https://localhost:3000/icons']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
	/*Required*/
	metadata,

	/*Optional*/
	enableEIP6963: true, // true by default
	enableInjected: true, // true by default
	enableCoinbase: true, // true by default
	rpcUrl: mainnet.rpcUrl, //??   '...', // used for the Coinbase SDK
	defaultChainId: mainnet.chainId,  //??  1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
	ethersConfig,
	chains: [mainnet],
	projectId,
	enableAnalytics: true // Optional - defaults to your Cloud configuration
})

// -----------------------------------------------------------------------------------------


//const USDTAddress = '0x617f3112bf5397D0467D315cC709EF968D9ba546'
const USDTAddress = '0x55d398326f99059fF775485246999027B3197955'

// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)
/*const USDTAbi = [
	'function name() view returns (string)',
	'function symbol() view returns (string)',
	'function balanceOf(address) view returns (uint)',
	'function transfer(address to, uint amount)',
	'event Transfer(address indexed from, address indexed to, uint amount)'
]*/




// -----------------------------------------------------------------------------------------


function App() {
	const { address, chainId, isConnected } = useWeb3ModalAccount()
	const { walletProvider } = useWeb3ModalProvider()

	async function getBalance() {
		debugger;
		if (!isConnected || !walletProvider) throw Error('User disconnected')

		console.log('---------------------------------------');
		console.log(address);


		const ethersProvider = new BrowserProvider(walletProvider)
		const signer = await ethersProvider.getSigner()
		// The Contract object
		const USDTContract = new Contract(USDTAddress, usdt_abi, signer)
		const USDTBalance = await USDTContract.balanceOf(address)

		console.log(formatUnits(USDTBalance, 18))
	}
	return (
		<div className="App">
			<w3m-button />
			<br />
			<br />
			<br />
			<br />
			<button onClick={getBalance}>Get User Balance</button>
		</div>
	);
}

export default App;
